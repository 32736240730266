import { Menu, MenuList } from '@chakra-ui/menu'
import { validateColor } from 'theme/colors'
import React from 'react'
import { MenuIconButton } from 'components/Button/Action/MenuIconButton'
import { VStack, Text, Divider, HStack, Switch } from '@chakra-ui/react'
import { getTypography } from 'theme/typographies'
import { ThemeButton } from 'components/Button/Action/ThemeButton'
import { useManageUserTheme, useSelectAggregators, useSetPreferPermits } from 'state/user/hooks'
import { isProductionEnv } from 'utils/env'
import { Impersonator } from '../impersonation'
import { useChainId } from 'state/globalNetwork/hooks'
import { TRADE_AGGREGATORS_PER_CHAIN } from 'types/1delta'
import { AggregatorSelectionEntry } from '../aggregators'
import { TransactionSettings } from '../txSettings'
import { Percent } from '@1delta/base-sdk'
import { ColorMode } from 'theme'
import { settings } from 'constants/1delta'

interface SettingsProps {
  theme: ColorMode
}

export const Settings: React.FC<SettingsProps> = ({ theme }) => {
  const chainId = useChainId()

  const [selectedAggregators, activateAggregator] = useSelectAggregators()

  const [preferPermit, switchPreferPermit] = useSetPreferPermits()

  const [, setTheme] = useManageUserTheme()

  const isDark = theme === ColorMode.DARK

  const handleChangeTheme = () => {
    setTheme(isDark ? ColorMode.LIGHT : ColorMode.DARK)
  }

  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuIconButton iconSrc={settings[theme]} isOpen={isOpen} />
          <MenuList
            minWidth="291px"
            padding="1rem"
            border="none"
            borderRadius={'0.5rem'}
            bg={validateColor('Surface/Surface-primary')}
            boxShadow="dark"
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
          >
            <VStack alignItems={'flex-start'} w="100%" gap="1rem">
              <Text
                style={getTypography('Typography/Captions/Normal/Caption 2')}
                color={validateColor('Text/Headings & Titles/Title-text')}
                lineHeight={1}
              >
                Settings
              </Text>
              <Divider borderColor={validateColor('Borders/Border-tertiary')} opacity={1} />
              <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
                <Text
                  style={getTypography('Typography/Small/Normal/Small 2')}
                  color={validateColor('Text/Lables/Label-text-disabled')}
                >
                  Interface Settings
                </Text>
                <HStack justifyContent={'space-between'} w="100%">
                  <Text
                    style={getTypography('Typography/Small/Normal/Small 1')}
                    color={validateColor('Text/Lables/Label-text-default')}
                  >
                    Expert Mode
                  </Text>
                  <Switch isDisabled />
                </HStack>
                <HStack justifyContent={'space-between'} w="100%">
                  <Text
                    style={getTypography('Typography/Small/Normal/Small 1')}
                    color={validateColor('Text/Lables/Label-text-default')}
                  >
                    Prefer Permits
                  </Text>
                  <Switch isChecked={preferPermit} onChange={switchPreferPermit} />
                </HStack>
              </VStack>
              <Divider borderColor={validateColor('Borders/Border-tertiary')} opacity={1} />
              <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
                <Text
                  style={getTypography('Typography/Small/Normal/Small 2')}
                  color={validateColor('Text/Lables/Label-text-disabled')}
                >
                  Transaction Settings
                </Text>
                <TransactionSettings placeholderSlippage={new Percent(3, 1000)} />
              </VStack>
              {/* THEME */}
              <Divider borderColor={validateColor('Borders/Border-tertiary')} opacity={1} />
              <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
                <Text
                  style={getTypography('Typography/Small/Normal/Small 2')}
                  color={validateColor('Text/Lables/Label-text-disabled')}
                >
                  Mode
                </Text>
                <HStack justifyContent={'space-between'} w="100%">
                  <Text
                    style={getTypography('Typography/Small/Normal/Small 1')}
                    color={validateColor('Text/Lables/Label-text-default')}
                  >
                    Color Mode
                  </Text>
                  <ThemeButton isDark={isDark} onClick={handleChangeTheme} />
                </HStack>
              </VStack>
              {/* TRADE AGGREGATION */}
              {chainId in TRADE_AGGREGATORS_PER_CHAIN &&
                TRADE_AGGREGATORS_PER_CHAIN[chainId].length > 0 && (
                  <>
                    <Divider borderColor={validateColor('Borders/Border-tertiary')} opacity={1} />
                    <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
                      <Text
                        style={getTypography('Typography/Small/Normal/Small 2')}
                        color={validateColor('Text/Lables/Label-text-disabled')}
                      >
                        Aggregators
                      </Text>
                      {TRADE_AGGREGATORS_PER_CHAIN[chainId].map((a) => (
                        <AggregatorSelectionEntry
                          key={a}
                          aggregator={a}
                          selectedAggregators={selectedAggregators}
                          triggerChange={() => activateAggregator(a)}
                        />
                      ))}
                    </VStack>
                  </>
                )}
              {/* IMPERSONATION */}
              {!isProductionEnv() && (
                <>
                  <Divider borderColor={validateColor('Borders/Border-tertiary')} opacity={1} />
                  <VStack gap="0.5rem" w="100%" alignItems={'flex-start'}>
                    <Text
                      style={getTypography('Typography/Small/Normal/Small 2')}
                      color={validateColor('Text/Lables/Label-text-disabled')}
                    >
                      Account Impersonation
                    </Text>
                    <Impersonator />
                  </VStack>
                </>
              )}
            </VStack>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
