import { Percent } from '@1delta/base-sdk'
import { useUserSlippageTolerance, useUserTransactionTTL } from 'state/user/hooks'
import ms from 'ms.macro'
import { useState } from 'react'
import { DEFAULT_DEADLINE_FROM_NOW } from 'constants/misc'
import { HStack, Input, Text, VStack } from '@chakra-ui/react'
import { SecondarySmallButton } from 'components/Button/Secondary'
import { getTypography } from 'theme/typographies'
import { validateColor } from 'theme/colors'

enum SlippageError {
  InvalidInput = 'InvalidInput',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

interface TransactionSettingsProps {
  placeholderSlippage: Percent // varies according to the context in which the settings dialog is placed
}

const THREE_DAYS_IN_SECONDS = ms`3 days` / 1000

export function TransactionSettings({ placeholderSlippage }: TransactionSettingsProps) {
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()

  const [deadline, setDeadline] = useUserTransactionTTL()

  const [slippageInput, setSlippageInput] = useState('')
  const [slippageError, setSlippageError] = useState<SlippageError | false>(false)

  const [deadlineInput, setDeadlineInput] = useState('')
  const [deadlineError, setDeadlineError] = useState<DeadlineError | false>(false)

  function parseSlippageInput(value: string) {
    // populate what the user typed and clear the error
    setSlippageInput(value)
    setSlippageError(false)

    if (value.length === 0) {
      setUserSlippageTolerance('auto')
    } else {
      const parsed = Math.floor(Number.parseFloat(value) * 100)

      if (!Number.isInteger(parsed) || parsed < 0 || parsed > 5000) {
        setUserSlippageTolerance('auto')
        if (value !== '.') {
          setSlippageError(SlippageError.InvalidInput)
        }
      } else {
        setUserSlippageTolerance(new Percent(parsed, 10_000))
      }
    }
  }

  const tooLow = userSlippageTolerance !== 'auto' && userSlippageTolerance.lessThan(new Percent(5, 10_000))
  const tooHigh = userSlippageTolerance !== 'auto' && userSlippageTolerance.greaterThan(new Percent(1, 100))

  function parseCustomDeadline(value: string) {
    // populate what the user typed and clear the error
    setDeadlineInput(value)
    setDeadlineError(false)

    if (value.length === 0) {
      setDeadline(DEFAULT_DEADLINE_FROM_NOW)
    } else {
      try {
        const parsed: number = Math.floor(Number.parseFloat(value) * 60)
        if (!Number.isInteger(parsed) || parsed < 60 || parsed > THREE_DAYS_IN_SECONDS) {
          setDeadlineError(DeadlineError.InvalidInput)
        } else {
          setDeadline(parsed)
        }
      } catch (error) {
        console.error(error)
        setDeadlineError(DeadlineError.InvalidInput)
      }
    }
  }

  return (
    <VStack gap="md">
      <HStack justifyContent="space-between" w="100%" alignContent="center">
        <Text
          style={getTypography('Typography/Small/Normal/Small 1')}
          color={validateColor('Text/Lables/Label-text-default')}
        >
          Slippage tolerance
        </Text>
        <HStack>
          <SecondarySmallButton
            onClick={() => {
              parseSlippageInput('')
            }}
            // active={userSlippageTolerance === 'auto'}
          >
            Auto
          </SecondarySmallButton>
          <HStack>
            {tooLow || tooHigh ? (
              <span role="img" aria-label="warning">
                ⚠️
              </span>
            ) : null}
            <Input
              style={getTypography('Typography/Body-Labels/Normal/Body')}
              color={slippageError ? 'red' : validateColor('Text/Lables/Label-text-default')}
              maxW="5rem"
              textAlign="right"
              height={'auto'}
              border={'none'}
              _focusVisible={{
                outline: 'none',
              }}
              placeholder={placeholderSlippage.toFixed(2)}
              value={
                slippageInput.length > 0
                  ? slippageInput
                  : userSlippageTolerance === 'auto'
                  ? ''
                  : userSlippageTolerance.toFixed(2)
              }
              onChange={(e) => parseSlippageInput(e.target.value)}
              onBlur={() => {
                setSlippageInput('')
                setSlippageError(false)
              }}
            />
            <Text
              style={getTypography('Typography/Small/Normal/Small 1')}
              color={validateColor('Text/Lables/Label-text-default')}
            >
              %
            </Text>
          </HStack>
        </HStack>
      </HStack>
      {slippageError || tooLow || tooHigh ? (
        <HStack
          style={{
            fontSize: '0.75rem',
            paddingTop: '0.1rem',
            color: slippageError ? 'red' : '#F3841E',
          }}
        >
          {slippageError ? (
            <>Enter a valid slippage percentage</>
          ) : tooLow ? (
            <>Your transaction may fail</>
          ) : (
            <>Your transaction may be frontrun</>
          )}
        </HStack>
      ) : null}
    </VStack>
  )
}
